import React from 'react'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'

import '../components/all.scss'
import { relative } from 'path'

const IndexPage = props => (
  <div>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Supervised Visitation - find and list supervised visitation providers, monitors, and supervisors"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Supervised Visitation</title>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
      />
    </Helmet>
    <section
      className="hero is-primary is-fullheight"
      style={{ position: 'relative' }}
    >
      <Img
        fluid={props.data.imageOne.childImageSharp.fluid}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        }}
      />
      <div className="hero-body">
        <div className="container" style={{ marginTop: 'auto' }}>
          <div className="columns">
            <div className="column is-one-third" style={{ marginTop: 'auto' }}>
              <form className="box">
                <h2 className="is-size-4 has-text-weight-bold">
                  Find a supervisor, monitor, or supervised visitation provider.
                </h2>
                <br />
                <div className="field">
                  <label className="label" htmlFor="where">
                    Where
                  </label>
                  <div className="control">
                    <input className="input" id="where" type="text" />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor="when">
                    When
                  </label>
                  <div className="control">
                    <input className="input" id="when" type="text" />
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <div className="field">
                      <label className="label" htmlFor="from">
                        From
                      </label>
                      <div className="control">
                        <input className="input" id="from" type="text" />
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="field">
                      <label className="label" htmlFor="to">
                        To
                      </label>
                      <div className="control">
                        <input className="input" id="to" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-grouped is-grouped-right">
                  <p className="control">
                    <a className="button is-primary is-medium" href="#">
                      Search
                    </a>
                  </p>
                </div>
              </form>
            </div>
            <div className="column is-one-third" style={{ marginTop: 'auto' }}>
              <form className="box">
                <h3 className="is-size-5">
                  I am a supervisor, monitor, or I represent a supervised
                  visitation provider and I would like to{' '}
                  <a className="has-text-primary" href="#">
                    enroll my services
                  </a>
                  .
                </h3>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

export default IndexPage

export const query = graphql`
  query {
    imageOne: file(relativePath: { eq: "scott-webb-59043-unsplash-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
